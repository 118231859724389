import { useRouter } from 'next/router';
import { useMutation, useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import {
  ADD_COMPETITOR_SET,
  DELETE_COMPETITOR_SET,
  ADD_LISTINGS,
  GET_COMP_GROUP,
  GET_COMP_SET_LISTINGS,
  DELETE_COMP_SET_LISTINGS,
} from '@apolloCli/queries/forYou';
import { Listing } from '@apolloCli/queries/listings';
import { useAreaInfo } from '@hooks/useAreaInfo';
import { ListNames } from '@components/dataEntry/AdvancedSearchBar/RelatedAreas/RelatedAreas';

import { useUiFilters } from './useUiFilters';
import { useUser } from './useUser';

export type DeleteCompSetResult = {
  removeCompetitorGroup: {
    competitorGroupId: number;
    success: boolean;
  };
};

export type DeleteListingCompSetResult = {
  removeListingFromCompetitorGroup: {
    success: boolean;
    competitorGroupId: number;
    listingUid: string;
    providerId: string;
    areaId: number;
  };
};

export type UpsertCompetitorGroup = {
  competitorGroupId: number;
  name: string;
  areaId: number;
  providerId: string;
  areaName?: string;
  position?: number;
  listName?: ListNames;
};

export type CreateListing = {
  competitorGroupId: number;
  listingUid: string;
  providerId: string;
  areaId?: number;
};

export type CompSetResult = {
  upsertCompetitorGroup: UpsertCompetitorGroup;
};

export type CompSetListingsResult = {
  getListingsFromCompetitorGroup: {
    totalCount: number;
    hasMore: boolean;
    competitorGroupId: number;
    name: string;
    areaId: number;
    providerId: string;
    listings: Listing[];
  };
};

export type ListingResult = {
  addListingToCompetitorGroup: CreateListing;
};

export type GroupResult = {
  getCompetitorGroups: {
    nextDefaultName: string;
    competitorGroups: UpsertCompetitorGroup[];
  };
};

export type CompSetInput = {
  competitorGroupId?: number;
  name: string;
  areaId?: number;
  providerId: string;
};

export type DeleteCompSetInput = {
  competitorGroupId: number;
};

export type DeleteListingCompSetInput = {
  competitorGroupId: number;
  listingUid: string;
  providerId: string;
  areaId?: number;
};

type Args = {
  competitorGroupId?: number;
  getAllComps?: boolean;
  aid?: number;
  fetchPolicy?: WatchQueryFetchPolicy;
  listingUid?: string;
  skipListings?: boolean;
  isAdvanceSearch?: boolean;
};

export const useCompetitorSet = ({
  competitorGroupId,
  getAllComps,
  aid,
  fetchPolicy = 'cache-and-network',
  listingUid,
  skipListings,
  isAdvanceSearch = false,
}: Args = {}) => {
  const { providerId } = useUiFilters();
  const { areaId: areaInfoId } = useAreaInfo();
  const { isLogin } = useUser();
  const { query, pathname } = useRouter();
  const { aid: aidRouter } = query;
  const areaId = Number(aidRouter) || areaInfoId || aid;
  const skipCompSets = isAdvanceSearch && pathname.includes('top-properties');

  const [createCompSet, { loading: loadingCompSet }] = useMutation<CompSetResult, CompSetInput>(ADD_COMPETITOR_SET);
  const [updateCompSetHandler, { loading: loadingUpdateCompSetHandler, error: updateCompSetHandlerError }] =
    useMutation<CompSetResult, CompSetInput>(ADD_COMPETITOR_SET);
  const [createListings, { loading: loadingCreateListings }] = useMutation<ListingResult, CreateListing>(ADD_LISTINGS);
  const [deleteCompSet, { loading: deleteCompSetLoading }] =
    useMutation<DeleteCompSetResult, DeleteCompSetInput>(DELETE_COMPETITOR_SET);
  const [deleteListingFromCompSet, { loading: deleteListintingFromCompSetLoading }] =
    useMutation<DeleteListingCompSetResult, DeleteListingCompSetInput>(DELETE_COMP_SET_LISTINGS);

  const {
    data,
    loading: loadingCompSets,
    error: errorCompSets,
    refetch,
  } = useQuery<GroupResult>(GET_COMP_GROUP, {
    skip: !isLogin || skipCompSets,
    variables: {
      areaId: getAllComps ? undefined : areaId,
      providerId,
      listingUid,
    },
    fetchPolicy,
  });

  const {
    data: listingsFromCompGroupData,
    loading: loadingListingsFromCompGroup,
    error: errorListingsFromCompetitorGroup,
    refetch: refetcListingsFromCompetitorGroupHandler,
  } = useQuery<CompSetListingsResult>(GET_COMP_SET_LISTINGS, {
    skip: !isLogin || !areaId || !competitorGroupId || skipListings,
    variables: {
      competitorGroupId,
      providerId,
      areaId,
    },
  });

  const createCompSetHandler = async (name: string) => {
    const result = await createCompSet({
      variables: {
        name,
        areaId,
        providerId,
      },
    });
    await refetch();
    return result;
  };

  const createListingsHandler = async (competitorGroupIdScope: number, listingUidScope: string) => {
    const result = await createListings({
      variables: {
        competitorGroupId: competitorGroupIdScope,
        listingUid: listingUidScope,
        providerId,
        areaId,
      },
    });
    await refetcListingsFromCompetitorGroupHandler();
    return result;
  };

  const renameCompSetHandler = async (competitorGroupIdScope: number, name: string) => {
    const result = await updateCompSetHandler({
      variables: {
        competitorGroupId: competitorGroupIdScope,
        name,
        areaId,
        providerId,
      },
    });
    await refetch();
    return result;
  };

  const deleteCompSetHandle = async (competitorGroupIdScope: number) => {
    const result = await deleteCompSet({ variables: { competitorGroupId: competitorGroupIdScope } });
    await refetch();
    return result;
  };

  const deleteListingCompSetHandle = async (competitorGroupIdScope: number, listingUidScope: string) => {
    const result = await deleteListingFromCompSet({
      variables: {
        competitorGroupId: competitorGroupIdScope,
        listingUid: listingUidScope,
        providerId,
        areaId,
      },
    });
    await refetch();
    return result;
  };

  return {
    listingsFromCompGroupData,
    compGroupData: data?.getCompetitorGroups,
    createListingsHandler,
    createCompSetHandler,
    renameCompSetHandler,
    deleteCompSetHandle,
    deleteListingCompSetHandle,
    loading:
      loadingCompSet ||
      loadingCreateListings ||
      deleteCompSetLoading ||
      loadingCompSets ||
      loadingListingsFromCompGroup ||
      loadingUpdateCompSetHandler ||
      deleteListintingFromCompSetLoading,
    error: errorCompSets || errorListingsFromCompetitorGroup || updateCompSetHandlerError,
    refetch,
    refetcListingsFromCompetitorGroupHandler,
  };
};
