import { useReactiveVar } from '@apollo/client';
import {
  filterArrangementTypeVar,
  filterHostTypeVar,
  filterInstantBookTypeVar,
  filterProviderTypeVar,
  filterBathroomTypeVar,
  filterGuestsTypeVar,
  filterRoomsTypeVar,
  filterPropertyTypeVar,
  filterAmenitiesTypeVar,
  filterRatingTypeVar,
  filterHouseRulesTypeVar,
  currentMapCoordinates,
} from '@apolloCli/policies/uiPolicy';
import {
  BOOKING_VALUES,
  HOST_TYPE_VALUES,
  BATHROOM_VALUES,
  GUESTS_VALUES,
  ROOMS_VALUES,
  AMENITIES_VALUES,
  RATING_VALUE,
  PROPERTY_TYPE_VALUES,
  HOUSERULES_VALUES,
  ARRANGEMENT_VALUES,
  PROVIDER_VALUES,
} from 'src/const/filters';

export type CustomFilters = {
  amenities?: string[];
  arrangementType?: string[];
  bathrooms?: string[];
  hostType?: string[];
  houseRules?: string[];
  instantBook?: string[];
  propertyType?: string[];
  provider?: string[];
  rating?: string;
  rooms?: string[];
  sleeps?: string[];
};

export const useUiFilters = () => {
  const filterProviderType = useReactiveVar(filterProviderTypeVar);
  const arrangementType = useReactiveVar(filterArrangementTypeVar);
  const filterHostType = useReactiveVar(filterHostTypeVar);
  const filterInstantBookType = useReactiveVar(filterInstantBookTypeVar);
  const filterBathroomType = useReactiveVar(filterBathroomTypeVar);
  const filterRatingType = useReactiveVar(filterRatingTypeVar);
  const filterGuestsType = useReactiveVar(filterGuestsTypeVar);
  const filterRoomsType = useReactiveVar(filterRoomsTypeVar);
  const filterPropertyType = useReactiveVar(filterPropertyTypeVar);
  const filterAmenitiesType = useReactiveVar(filterAmenitiesTypeVar);
  const rating = useReactiveVar(filterRatingTypeVar);
  const filterHouseRulesType = useReactiveVar(filterHouseRulesTypeVar);
  const mapCoordinates = useReactiveVar(currentMapCoordinates);
  const [providerId] = filterProviderType;
  const [hostType] = filterHostType;
  const [arrangementTypes] = arrangementType;
  const [instantBook] = filterInstantBookType;
  const [bathroom] = filterBathroomType;
  const [sleeps] = filterGuestsType;
  const [rooms] = filterRoomsType;
  const [propertyType] = filterPropertyType;
  const [amenities] = filterAmenitiesType;
  const [houseRules] = filterHouseRulesType;

  // reset filters when the component is locked
  const resetFilters = ({ resetAll }: { resetAll?: boolean } = {}) => {
    filterHostTypeVar(HOST_TYPE_VALUES);
    filterInstantBookTypeVar(BOOKING_VALUES);
    filterRoomsTypeVar(ROOMS_VALUES);
    filterBathroomTypeVar(BATHROOM_VALUES);
    filterGuestsTypeVar(GUESTS_VALUES);
    filterPropertyTypeVar(PROPERTY_TYPE_VALUES);
    filterAmenitiesTypeVar(AMENITIES_VALUES);
    filterRatingTypeVar(RATING_VALUE);
    filterHouseRulesTypeVar(HOUSERULES_VALUES);

    if (resetAll) {
      filterArrangementTypeVar(ARRANGEMENT_VALUES);
    }
  };

  const setFilters = ({
    amenities: customAmenities,
    arrangementType: customArrangementType,
    bathrooms: customBathrooms,
    hostType: customHostType,
    houseRules: customHouseRules,
    instantBook: customInstantBook,
    propertyType: customPropertyType,
    provider: customProvider,
    rating: customRating,
    rooms: customRooms,
    sleeps: customSleeps,
  }: CustomFilters) => {
    filterAmenitiesTypeVar(customAmenities ?? AMENITIES_VALUES);
    filterArrangementTypeVar(customArrangementType ?? ARRANGEMENT_VALUES);
    filterBathroomTypeVar(customBathrooms ?? BATHROOM_VALUES);
    filterHostTypeVar(customHostType ?? HOST_TYPE_VALUES);
    filterHouseRulesTypeVar(customHouseRules ?? HOUSERULES_VALUES);
    filterInstantBookTypeVar(customInstantBook ?? BOOKING_VALUES);
    filterPropertyTypeVar(customPropertyType ?? PROPERTY_TYPE_VALUES);
    filterProviderTypeVar(customProvider ?? PROVIDER_VALUES);
    filterRatingTypeVar(customRating ?? RATING_VALUE);
    filterRoomsTypeVar(customRooms ?? ROOMS_VALUES);
    filterGuestsTypeVar(customSleeps ?? GUESTS_VALUES);
  };

  return {
    defaultFilters:
      providerId.includes('airbnb') &&
      arrangementTypes.includes('all') &&
      hostType.includes('all') &&
      instantBook.includes('all') &&
      bathroom.includes('all') &&
      sleeps.includes('all') &&
      propertyType.includes('all') &&
      rating === '20+' &&
      amenities.includes('all') &&
      houseRules.includes('all') &&
      rooms.includes('all'),
    providerId,
    arrangementTypes,
    hostType,
    propertyType,
    instantBook,
    bathroom,
    sleeps,
    rooms,
    amenities,
    rating,
    houseRules,
    resetFilters,
    setFilters,
    mapCoordinates,
    completeFilters: {
      filterHostType,
      arrangementType,
      filterInstantBookType,
      filterBathroomType,
      filterGuestsType,
      filterRoomsType,
      filterPropertyType,
      filterAmenitiesType,
      filterHouseRulesType,
      filterProviderType,
      filterRatingType,
    },
  };
};
