import { PropertyTypeEnum } from '@utils/enums';

export type FilterName =
  | 'providerId'
  | 'arrangementTypes'
  | 'hostType'
  | 'instantBook'
  | 'bathrooms'
  | 'sleeps'
  | 'rooms'
  | 'propertyType'
  | 'amenities'
  | 'listings'
  | 'houseRules';

export type OptionFilterType = {
  name: string;
  label: string;
  labelPicked?: string;
  options?: OptionFilterType[];
  canBeLocked?: boolean;
  canBeDisabled?: boolean;
  labelDisabled?: string;
};

export const ENTIRE_HOME_OPTIONS: OptionFilterType[] = [
  {
    name: '0',
    label: 'Studio',
    labelPicked: 'Studio',
  },
  {
    name: '1',
    label: '1 Bedroom',
    labelPicked: '+1 Bedrooms',
  },
  {
    name: '2',
    label: '2 Bedrooms',
    labelPicked: '+2 Bedrooms',
  },
  {
    name: '3',
    label: '3 Bedrooms',
    labelPicked: '+3 Bedrooms',
  },
  {
    name: '4',
    label: '4 Bedrooms',
    labelPicked: '4 Bedrooms',
  },
  {
    name: '5+',
    label: '+5 Bedrooms',
    labelPicked: '+5 Bedrooms',
  },
];

export const AMENITIES_OPTIONS: OptionFilterType[] = [
  {
    name: 'hasWorkspace',
    label: 'Workspace',
    labelPicked: 'Workspace',
  },
  {
    name: 'hasWifi',
    label: 'Wifi',
    labelPicked: 'Wifi',
  },
  {
    name: 'hasPool',
    label: 'Pool',
    labelPicked: 'Pool',
  },
  {
    name: 'hasHotTub',
    label: 'Hot Tub',
    labelPicked: 'Hot Tub',
  },
  {
    name: 'hasParking',
    label: 'Parking',
    labelPicked: 'Parking',
  },
];

export const HOUSERULES_OPTIONS: OptionFilterType[] = [
  {
    name: 'eventsAllowed',
    label: 'Events Allowed',
    labelPicked: 'Events Allowed',
  },
  {
    name: 'smokingAllowed',
    label: 'Smoking Allowed',
    labelPicked: 'Smoking Allowed',
  },
  {
    name: 'has24HourCheckIn',
    label: '24 Hour Check-in',
    labelPicked: '24 Hour Check-in',
  },
  {
    name: 'hasFamilyFriendly',
    label: 'Family Friendly',
    labelPicked: 'Family Friendly',
  },
  {
    name: 'petsAllowed',
    label: 'Pets Allowed',
    labelPicked: 'Pets Allowed',
  },
];

export const ARRANGEMENT_OPTIONS: OptionFilterType[] = [
  {
    name: PropertyTypeEnum.PRIVATE_ROOM,
    label: 'Private Room',
    labelPicked: 'Private Room',
    canBeLocked: true,
  },
  {
    name: PropertyTypeEnum.SHARED_ROOM,
    label: 'Shared Room',
    labelPicked: 'Shared Room',
    canBeLocked: true,
  },
  {
    name: PropertyTypeEnum.ENTIRE_HOME,
    label: 'Entire Place',
    labelPicked: 'Entire Home',
    canBeLocked: true,
  },
  {
    name: PropertyTypeEnum.HOTEL,
    label: 'Hotel',
    labelPicked: 'Hotel',
    canBeLocked: true,
  },
];

export const LISTING_OPTIONS: OptionFilterType[] = [
  {
    name: 'listings',
    label: 'Active Listings Only',
    labelPicked: 'Active Listings Only',
    canBeLocked: false,
  },
];

export const BATHROOM_OPTIONS: OptionFilterType[] = [
  {
    name: '1',
    label: '1 Bathroom',
    labelPicked: '+1 Bathroom',
  },
  {
    name: '2',
    label: '2 Bathrooms',
    labelPicked: '+2 Bathroom',
  },
  {
    name: '3',
    label: '3 Bathrooms',
    labelPicked: '+3 Bathroom',
  },
  {
    name: '4',
    label: '4 Bathrooms',
    labelPicked: '4 Bathroom',
  },
  {
    name: '5+',
    label: '+5 Bathrooms',
    labelPicked: '+5 Bathroom',
  },
];

export const GUESTS_OPTIONS: OptionFilterType[] = [
  {
    name: '1',
    label: '1 Guest',
    labelPicked: '1 Guest',
  },
  {
    name: '6',
    label: '6 Guests',
    labelPicked: '6 Guests',
  },
  {
    name: '2',
    label: '2 Guests',
    labelPicked: '2 Guests',
  },
  {
    name: '7',
    label: '7 Guests',
    labelPicked: '7 Guests',
  },
  {
    name: '3',
    label: '3 Guests',
    labelPicked: '3 Guests',
  },
  {
    name: '8',
    label: '8 Guests',
    labelPicked: '8 Guests',
  },
  {
    name: '4',
    label: '4 Guests',
    labelPicked: '4 Guests',
  },
  {
    name: '9',
    label: '9 Guests',
    labelPicked: '9 Guests',
  },
  {
    name: '5',
    label: '5 Guests',
    labelPicked: '5 Guests',
  },
  {
    name: '10+',
    label: '10+ Guests',
    labelPicked: '10+ Guests',
  },
];

export const PROVIDER_OPTIONS: OptionFilterType[] = [
  {
    name: 'airbnb',
    label: 'Airbnb',
    labelPicked: 'Airbnb',
  },
  {
    name: 'havrbo',
    label: 'Vrbo',
    labelPicked: 'Vrbo',
    canBeDisabled: true,
  },
];

export const HOST_TYPE_OPTIONS: OptionFilterType[] = [
  {
    name: 'independent',
    label: 'Independent (1-2 Listings)',
    labelPicked: 'Independently Managed',
    canBeLocked: true,
  },
  {
    name: 'professional',
    label: 'Professional (+3 Listings)',
    labelPicked: 'Professionally Managed',
    canBeLocked: true,
  },
  {
    name: 'is_superhost',
    label: 'Superhost',
    labelPicked: 'Superhost',
    canBeLocked: true,
  },
];

export const HOST_TYPE_OPTIONS_VRBO: OptionFilterType[] = [
  {
    name: 'independent',
    label: 'By Owner',
    labelPicked: 'By Owner',
    canBeLocked: true,
  },
  {
    name: 'professional',
    label: 'Property Manager',
    labelPicked: 'Property Manager',
    canBeLocked: true,
  },
  {
    name: 'is_superhost',
    label: 'Premier Partner',
    labelPicked: 'Premier Partner',
    canBeLocked: true,
  },
];

export const PROPERTY_TYPE_OPTIONS: OptionFilterType[] = [
  {
    name: 'apartment',
    label: 'Apartment',
    labelPicked: 'apartment',
    canBeLocked: true,
  },
  {
    name: 'cabin',
    label: 'Cabin',
    labelPicked: 'cabin',
    canBeLocked: true,
  },
  {
    name: 'condo',
    label: 'Condo',
    labelPicked: 'condo',
    canBeLocked: true,
  },
  {
    name: 'house',
    label: 'House',
    labelPicked: 'house',
    canBeLocked: true,
  },
  {
    name: 'other',
    label: 'Other',
    labelPicked: 'other',
    canBeLocked: true,
  },
];

export const BOOKING_OPTIONS: OptionFilterType[] = [
  {
    name: 'only_instant_book',
    label: 'Only Instant Book',
    labelPicked: 'Only Instant Book',
    canBeLocked: true,
  },
  {
    name: 'no_instant_book',
    label: 'Not Instant Book',
    labelPicked: 'Not Instant Book',
    canBeLocked: true,
  },
];

export const STATS_BY_CASE = {
  adr: 'adr',
  adjOccupancyRate: 'adj_occupancy_rate',
  avgAvailableCount: 'avg_available_count',
  adjRevpal: 'adj_revpal',
  grossRevenue: 'gross_revenue',
  avgNbPerAvail: 'avg_nb_per_avail',
  adjRevpan: 'adj_revpan',
  avgLeadTime: 'avg_lead_time',
  nightsBooked: 'nights_booked',
  listedNightRate: 'listed_night_rate',
  q_adr: 'adr',
  q_adjOccupancyRate: 'adj_occupancy_rate',
  q_adjRevpal: 'adj_revpal',
  q_grossRevenue: 'gross_revenue',
  q_adjRevpan: 'adj_revpan',
  q_nightsBooked: 'nights_booked',

  // compatibility
  adr_booked: 'adr',
  adj_occupancy_rate: 'adjOccupancyRate',
  avg_available_listing_count: 'avgAvailableCount',
  adj_revpal: 'adjRevpal',
  gross_revenue: 'grossRevenue',
  avg_nights_booked_per_available_listing: 'avgNbPerAvail',
  adj_revpan: 'adjRevpan',
  avg_booking_lead_time: 'avgLeadTime',
  nights_booked: 'nightsBooked',
  q_adr_booked: 'adr',
  q_adj_occupancy_rate: 'adj_occupancy_rate',
  q_adj_revpal: 'adj_revpal',
  q_gross_revenue: 'gross_revenue',
  q_adj_revpan: 'adj_revpan',
};

export const PROVIDER_VALUES = ['airbnb'];
export const ARRANGEMENT_VALUES = ['all'];
export const HOST_TYPE_VALUES = ['all'];
export const BOOKING_VALUES = ['all'];
export const BATHROOM_VALUES = ['all'];
export const GUESTS_VALUES = ['all'];
export const ROOMS_VALUES = ['all'];
export const PROPERTY_TYPE_VALUES = ['all'];
export const AMENITIES_VALUES = ['all'];
export const RATING_VALUE = '20+';
export const HOUSERULES_VALUES = ['all'];
