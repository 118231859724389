/* eslint-disable max-len */
import React, { FC } from 'react';

type IconProps = {
  fill?: string;
  stroke?: string;
  className?: string;
};

export const HeartIcon: FC<IconProps> = ({ fill, className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" className={className} viewBox="0 0 12.9 11.29">
    <path
      id="heart"
      d="M11.549,32.792a3.8,3.8,0,0,0-5.1.378,3.792,3.792,0,0,0-5.1-.378,3.812,3.812,0,0,0-.267,5.573l4.419,4.5a1.323,1.323,0,0,0,1.895,0l4.419-4.5A3.815,3.815,0,0,0,11.549,32.792Zm-.595,4.724-4.419,4.5a.1.1,0,0,1-.171,0l-4.419-4.5a2.6,2.6,0,0,1,.184-3.8,2.554,2.554,0,0,1,3.439.265l.882.9.882-.9a2.553,2.553,0,0,1,3.439-.267A2.609,2.609,0,0,1,10.955,37.516Z"
      transform="translate(0 -31.978)"
      fill={fill}
    />
  </svg>
);

export const HeartIconActive: FC<IconProps> = ({ fill, className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" className={className} viewBox="0 0 12.9 11.29">
    <path
      id="heart"
      d="M11.549,32.792a3.8,3.8,0,0,0-5.1.378,3.792,3.792,0,0,0-5.1-.378,3.812,3.812,0,0,0-.267,5.573l4.419,4.5a1.323,1.323,0,0,0,1.895,0l4.419-4.5A3.815,3.815,0,0,0,11.549,32.792Z"
      transform="translate(0 -31.978)"
      fill={fill}
    />
  </svg>
);
