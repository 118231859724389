import gql from 'graphql-tag';

export const ADD_CUSTOM_AREA = gql`
  mutation MutationCustomMarket($customMarketId: Int, $filters: FiltersInput!, $areaId: Int!, $name: String) {
    upsertCustomMarket(customMarketId: $customMarketId, filters: $filters, areaId: $areaId, name: $name) {
      customMarketId
      name
      filters {
        provider
        arrangementType
        instantBook
        hostType
        rooms
        sleeps
        bathrooms
        houseRules
        amenities
        rating
        propertyType
      }
      areaId
    }
  }
`;

export const GET_SAVED_MARKETS = gql`
  query GetCustomMarkets($areaId: Int, $customMarketId: Int) {
    getCustomMarkets(areaId: $areaId, customMarketId: $customMarketId) {
      nextDefaultName
      customMarkets {
        id
        name
        areaId
        areaName
        filters {
          provider
          arrangementType
          instantBook
          hostType
          rooms
          sleeps
          bathrooms
          houseRules
          amenities
          rating
          propertyType
        }
        status
      }
    }
  }
`;

export const DELETE_SAVED_AREA = gql`
  mutation RemoveCustomMarket($customMarketId: Int!) {
    removeCustomMarket(customMarketId: $customMarketId) {
      customMarketId
      result
    }
  }
`;

export const DELETE_COMPETITOR_SET = gql`
  mutation RemoveCompetitorGroup($competitorGroupId: Int!) {
    removeCompetitorGroup(competitorGroupId: $competitorGroupId) {
      result
      competitorGroupId
    }
  }
`;

export const ADD_COMPETITOR_SET = gql`
  mutation UpsertCompetitorGroup($competitorGroupId: Int, $name: String, $areaId: Int, $providerId: String) {
    upsertCompetitorGroup(
      competitorGroupId: $competitorGroupId
      name: $name
      areaId: $areaId
      providerId: $providerId
    ) {
      competitorGroupId
      name
      areaId
      providerId
    }
  }
`;

export const ADD_LISTINGS = gql`
  mutation AddListingToCompetitorGroup(
    $competitorGroupId: Int!
    $listingUid: String!
    $providerId: String!
    $areaId: Int!
  ) {
    addListingToCompetitorGroup(
      competitorGroupId: $competitorGroupId
      listingUid: $listingUid
      providerId: $providerId
      areaId: $areaId
    ) {
      success
      competitorGroupId
      listingUid
      providerId
      areaId
    }
  }
`;

export const GET_COMP_GROUP = gql`
  query GetCompetitorGroups($areaId: Int, $providerId: String, $listingUid: String) {
    getCompetitorGroups(areaId: $areaId, providerId: $providerId, listingUid: $listingUid) {
      nextDefaultName
      competitorGroups {
        competitorGroupId
        name
        areaName
        areaId
        providerId
      }
    }
  }
`;

export const GET_COMP_SET_LISTINGS = gql`
  query GetListingsFromCompetitorGroup(
    $competitorGroupId: Int
    $areaId: Int!
    $providerId: String!
    $limit: Int
    $startingAfter: String
    $endingBefore: String
  ) {
    getListingsFromCompetitorGroup(
      competitorGroupId: $competitorGroupId
      areaId: $areaId
      providerId: $providerId
      limit: $limit
      startingAfter: $startingAfter
      endingBefore: $endingBefore
    ) {
      totalCount
      hasMore
      competitorGroupId
      name
      areaId
      providerId
      listings {
        areaId
        areaType
        areaName
        uid
        providerId
        name
        arrangementType
        instantBook
        isManaged
        latitude
        url
        longitude
        sleeps
        beds
        bedrooms
        bathrooms
        beds
        image {
          t
          n
          caption
        }
        vrps {
          value
          month
        }
        isSuperhost
        dailyRate
        occupancyRate
        trackedId
        rating
        reviewsCount
        grossRevenueLtm
      }
    }
  }
`;

export const DELETE_COMP_SET_LISTINGS = gql`
  mutation RemoveListingFromCompetitorGroup(
    $competitorGroupId: Int
    $listingUid: String!
    $providerId: String!
    $areaId: Int
  ) {
    removeListingFromCompetitorGroup(
      competitorGroupId: $competitorGroupId
      listingUid: $listingUid
      providerId: $providerId
      areaId: $areaId
    ) {
      success
      competitorGroupId
      listingUid
      providerId
      areaId
    }
  }
`;
